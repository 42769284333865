import * as React from "react";

export const CheckCircleIcon = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10C19.994 4.48 15.52.006 10 0Z"
      fill="#FFCD00"
    />
    <path
      d="m15.772 6.834-5.704 7.74a.843.843 0 0 1-1.192.157l-4.073-3.256a.834.834 0 0 1 1.042-1.302L9.24 12.89l5.19-7.042a.833.833 0 1 1 1.341.986Z"
      fill="#fff"
    />
  </svg>
);
