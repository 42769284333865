import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { TestimonialDesktop } from "./testimonial-desktop";
// import { TestimonialMobile } from "./testimonial-mobile";
import { TestimonialMobile } from "./carousel-mobile";
import { DemoCarousel } from "./carousel";
const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const Testimonial = () => {
  const { desktop, mobile } = useStyles();
  return (
    <>
      <Box >
        {/* <TestimonialDesktop /> */}
        <DemoCarousel />
      </Box>
      {/* <Box className={mobile}>
        <TestimonialMobile />
      </Box> */}
    </>
  );
};
