import { Box, Typography  } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TypeAnimation from 'react-type-animation';
import React, { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

const TEAM = [
  {
      "name": "Alexandra",
      "location": "Washington",
      "bookTitle": "The Odyssey",
      "author": "Homer"
  },
  {
      "name": "Allison",
      "location": "Florida",
      "bookTitle": "Ethan Frome",
      "author": "Edith Wharton"
  },
  {
      "name": "Amanda",
      "location": "North Dakota",
      "bookTitle": "Lord of the Flies",
      "author": "William Golding"
  },
  {
      "name": "Amy",
      "location": "California",
      "bookTitle": "Animal Farm",
      "author": "George Orwell"
  },
  {
      "name": "Amy",
      "location": "New York",
      "bookTitle": "The Hobbit",
      "author": "J. R. R. Tolkien"
  },
  {
      "name": "Andrew",
      "location": "New York",
      "bookTitle": "Stella by Starlight",
      "author": "Sharon Draper"
  },
  {
      "name": "Angelina",
      "location": "Oregon",
      "bookTitle": "Maniac Magee",
      "author": "Jerry Spinelli"
  },
  {
      "name": "Anju",
      "location": "Colorado",
      "bookTitle": "The Bronze Bow",
      "author": "Elizabeth George Speare"
  },
  {
      "name": "Ann",
      "location": "California",
      "bookTitle": "Black Beauty",
      "author": "Anna Sewell"
  },
  {
      "name": "Ann-Marie",
      "location": "Nebraska",
      "bookTitle": "Harrison Bergeron",
      "author": "Kurt Vonnegut"
  },
  {
      "name": "Annabelle",
      "location": "Singapore",
      "bookTitle": "Othello",
      "author": "William Shakespeare"
  },
  {
      "name": "Anne",
      "location": "Ontario",
      "bookTitle": "The Ransom of Mercy Carter",
      "author": "Caroline B. Cooney"
  },
  {
      "name": "Annika",
      "location": "California",
      "bookTitle": "A Raisin in the Sun",
      "author": "Lorraine Hansberry"
  },
  {
      "name": "Arian",
      "location": "Florida",
      "bookTitle": "The Catcher in the Rye",
      "author": "J. D. Salinger"
  },
  {
      "name": "Ashley",
      "location": "Texas",
      "bookTitle": "Native Son",
      "author": "Richard Wright"
  },
  {
      "name": "Atrayee",
      "location": "California",
      "bookTitle": "A Separate Peace",
      "author": "John Knowles"
  },
  {
      "name": "Atrayee",
      "location": "UK",
      "bookTitle": "One Crazy Summer",
      "author": "Rita Williams-Garcia"
  },
  {
      "name": "Audrey",
      "location": "California",
      "bookTitle": "Atonement",
      "author": "Ian McEwan"
  },
  {
      "name": "Audrey",
      "location": "California",
      "bookTitle": "Bronx Masquerade",
      "author": "Nikki Grimes"
  },
  {
      "name": "Audrey",
      "location": "New York",
      "bookTitle": "I Am Malala",
      "author": "Malala Yousafzai"
  },
  {
      "name": "Audrey",
      "location": "California",
      "bookTitle": "The Breadwinner",
      "author": "Deborah Ellis"
  },
  {
      "name": "Avery",
      "location": "Illinois",
      "bookTitle": "Redwall",
      "author": "Brian Jacques"
  },
  {
      "name": "Ayelet",
      "location": "California",
      "bookTitle": "A Thousand Splendid Suns",
      "author": "Khaled Hosseini"
  },
  {
      "name": "Barb",
      "location": "Illinois",
      "bookTitle": "Freedom Crossing",
      "author": "Margaret Goff Clark"
  },
  {
      "name": "Becki",
      "location": "Florida",
      "bookTitle": "Flora and Ulysses",
      "author": "Kate DiCamillo"
  },
  {
      "name": "Benjamin",
      "location": "California",
      "bookTitle": "Across Five Aprils",
      "author": "Irene Hunt"
  },
  {
      "name": "Benjamin",
      "location": "New York",
      "bookTitle": "Harry Potter Goblet of Fire",
      "author": "JK Rowling"
  },
  {
      "name": "Betsy",
      "location": "South Dakota",
      "bookTitle": "Much Ado About Nothing",
      "author": "William Shakespeare"
  },
  {
      "name": "Betty",
      "location": "California",
      "bookTitle": "A Midsummer Night's Dream",
      "author": "William Shakespeare"
  },
  {
      "name": "Bonnie",
      "location": "Utah",
      "bookTitle": "Night",
      "author": "Elie Wiesel"
  },
  {
      "name": "Bryan",
      "location": "Massachusetts",
      "bookTitle": "To Kill a Mockingbird",
      "author": "Harper Lee"
  },
  {
      "name": "Camila",
      "location": "Missouri",
      "bookTitle": "Harriet The Spy",
      "author": "Louise Fitzhugh"
  },
  {
      "name": "Carri",
      "location": "California",
      "bookTitle": "Al Capone Does My Shirts",
      "author": "Gennifer Choldenko"
  },
  {
      "name": "Carri",
      "location": "New York",
      "bookTitle": "Heart of Darkness",
      "author": "Joseph Conrad"
  },
  {
      "name": "Chatom",
      "location": "Montana",
      "bookTitle": "Harriet Tubman, Secret Agent",
      "author": "Thomas B. Allen"
  },
  {
      "name": "Chetna",
      "location": "New Mexico",
      "bookTitle": "Harry Potter and the Prisoner of Azkaban",
      "author": "J.K. Rowling"
  },
  {
      "name": "Chris",
      "location": "New York",
      "bookTitle": "The Tale of Despereaux",
      "author": "Kate DiCamillo"
  },
  {
      "name": "Christine",
      "location": "Florida",
      "bookTitle": "Charlie and the Chocolate Factory",
      "author": "Roald Dahl"
  },
  {
      "name": "Christine",
      "location": "Georgia",
      "bookTitle": "Flowers for Algernon",
      "author": "Daniel Keyes"
  },
  {
      "name": "Cindy",
      "location": "Oklahoma",
      "bookTitle": "Macbeth",
      "author": "William Shakespeare"
  },
  {
      "name": "Claire",
      "location": "Alaska",
      "bookTitle": "A Farewell to Arms",
      "author": "Ernest Hemingway"
  },
  {
      "name": "Courtney",
      "location": "Florida",
      "bookTitle": "Fahrenheit 451",
      "author": "Ray Bradbury"
  },
  {
      "name": "Darlene",
      "location": "West Virginia",
      "bookTitle": "Of Mice and Men",
      "author": "John Steinbeck"
  },
  {
      "name": "David",
      "location": "Florida",
      "bookTitle": "Chasing Vermeer",
      "author": "Blue Balliett"
  },
  {
      "name": "David",
      "location": "Illinois",
      "bookTitle": "Roll of Thunder, Hear My Cry",
      "author": "Mildred D. Taylor"
  },
  {
      "name": "David",
      "location": "Illinois",
      "bookTitle": "Romeo and Juliet",
      "author": "William Shakespeare"
  },
  {
      "name": "Deana",
      "location": "Prince Edward Island",
      "bookTitle": "The Raven",
      "author": "Edgar Allan Poe"
  },
  {
      "name": "Devon",
      "location": "Massachusetts",
      "bookTitle": "Zach's Lie",
      "author": "Roland Smith"
  },
  {
      "name": "Dietrich",
      "location": "New Brunswick",
      "bookTitle": "Phoenix Rising",
      "author": "Karen Hesse"
  },
  {
      "name": "Donna",
      "location": "California",
      "bookTitle": "Alice's Adventures in Wonderland",
      "author": "Lewis Carroll"
  },
  {
      "name": "Donna",
      "location": "New York",
      "bookTitle": "Heidi",
      "author": "Johanna Spyri"
  },
  {
      "name": "Ella",
      "location": "California",
      "bookTitle": "Anne Frank: The Diary of a Young Girl",
      "author": "Anne Frank"
  },
  {
      "name": "Ella",
      "location": "California",
      "bookTitle": "Black Hands, White Sails",
      "author": "F & P McKissack"
  },
  {
      "name": "Ella",
      "location": "New York",
      "bookTitle": "Holes",
      "author": "Louis Sachar"
  },
  {
      "name": "Elle",
      "location": "Florida",
      "bookTitle": "D'Aulaires' Book of Greek Myths",
      "author": "D'Aulaire and D'Aulaire"
  },
  {
      "name": "Ellen",
      "location": "Florida",
      "bookTitle": "Death of a Salesman",
      "author": "Arthur Miller"
  },
  {
      "name": "Ellie",
      "location": "South Carolina",
      "bookTitle": "The Maze Runner",
      "author": "James Dashner"
  },
  {
      "name": "Evan",
      "location": "Florida",
      "bookTitle": "The City of Ember",
      "author": "Jeanne DuPrau"
  },
  {
      "name": "Freedom",
      "location": "Oman",
      "bookTitle": "Passing",
      "author": "Nella Larsen"
  },
  {
      "name": "Gavin",
      "location": "Austrailia",
      "bookTitle": "Our Town",
      "author": "Thornton Wilder"
  },
  {
      "name": "Gena",
      "location": "Manitoba",
      "bookTitle": "The Phantom Tollbooth",
      "author": "Norton Juster"
  },
  {
      "name": "Grace",
      "location": "Florida",
      "bookTitle": "Efrén Divided",
      "author": "Ernesto Cisneros"
  },
  {
      "name": "HaShachar",
      "location": "California",
      "bookTitle": "A Wind In The Door",
      "author": "Madeleine L'Engle"
  },
  {
      "name": "Hauke",
      "location": "Alabama",
      "bookTitle": "A Case of Identity",
      "author": "Sir Arthur Conan Doyle"
  },
  {
      "name": "Hayden",
      "location": "Illinois",
      "bookTitle": "Sir Gawain and the Green Knight",
      "author": "anonymous"
  },
  {
      "name": "Heather",
      "location": "New York",
      "bookTitle": "Harry Potter and the Sorcerer's Stone",
      "author": "J. K. Rowling"
  },
  {
      "name": "Henry",
      "location": "New Zealand",
      "bookTitle": "Out of Many Waters",
      "author": "Jacqueline Greene"
  },
  {
      "name": "Humzah",
      "location": "Hawaii",
      "bookTitle": "Frankenstein",
      "author": "Mary Shelley"
  },
  {
      "name": "Isabella",
      "location": "Massachusetts",
      "bookTitle": "When You Reach Me",
      "author": "Rebecca Stead"
  },
  {
      "name": "Jackie",
      "location": "Ohio",
      "bookTitle": "The Lord of the Rings",
      "author": "J.R.R Tolkien"
  },
  {
      "name": "Jacquelyne",
      "location": "California",
      "bookTitle": "Bridge to Terabithia",
      "author": "Katherine Paterson"
  },
  {
      "name": "Jacquelyne",
      "location": "North Carolina",
      "bookTitle": "I Know Why the Caged Bird Sings",
      "author": "Maya Angelou"
  },
  {
      "name": "Jacquelyne",
      "location": "California",
      "bookTitle": "The Bell Jar",
      "author": "Sylvia Plath"
  },
  {
      "name": "Jessica",
      "location": "California",
      "bookTitle": "Around the World in Eighty Days",
      "author": "Jules Verne"
  },
  {
      "name": "Jessica",
      "location": "California",
      "bookTitle": "Born a Crime (Young Readers' Edition)",
      "author": "Trevor Noah"
  },
  {
      "name": "Jessica",
      "location": "New York",
      "bookTitle": "The Hunger Games",
      "author": "Suzanne Collins"
  },
  {
      "name": "Jia",
      "location": "Wisconsin",
      "bookTitle": "The Old Man and the Sea",
      "author": "Ernest Hemingway"
  },
  {
      "name": "Jill",
      "location": "Florida",
      "bookTitle": "Chasing Redbird",
      "author": "Sharon Creech"
  },
  {
      "name": "Jocelyn",
      "location": "UAE ",
      "bookTitle": "Paradise Lost",
      "author": "John Milton"
  },
  {
      "name": "Jonathan",
      "location": "California",
      "bookTitle": "The Adventures of Huckleberry Finn",
      "author": "Mark Twain"
  },
  {
      "name": "Joseph",
      "location": "South Africa",
      "bookTitle": "Outcasts United",
      "author": "Warren St. John"
  },
  {
      "name": "Joshua",
      "location": "Illinois",
      "bookTitle": "Shiloh",
      "author": "Phyllis Reynolds Naylor"
  },
  {
      "name": "Judith",
      "location": "Nevada",
      "bookTitle": "Harry Potter and the Goblet of Fire",
      "author": "JK Rowling"
  },
  {
      "name": "Kalkin",
      "location": "Wyoming",
      "bookTitle": "Old Yeller",
      "author": "Fred Gipson"
  },
  {
      "name": "Kalliope",
      "location": "Vermont",
      "bookTitle": "Nineteen Eighty-Four",
      "author": "George Orwell"
  },
  {
      "name": "Katelyn",
      "location": "New York",
      "bookTitle": "Things Fall Apart",
      "author": "Chinua Achebe"
  },
  {
      "name": "Katherine",
      "location": "Kansas",
      "bookTitle": "The Gift of the Magi",
      "author": "O. Henry"
  },
  {
      "name": "Katie",
      "location": "Iowa",
      "bookTitle": "Frindle",
      "author": "Andrew Clements"
  },
  {
      "name": "Katina",
      "location": "Nova Scotia",
      "bookTitle": "Pygmalion",
      "author": "George Bernard Shaw"
  },
  {
      "name": "Keisha",
      "location": "Florida",
      "bookTitle": "Elijah of Buxton",
      "author": "Christopher Paul Curtis"
  },
  {
      "name": "Kelly",
      "location": "Louisiana",
      "bookTitle": "The Glass Menagerie",
      "author": "Tennessee Williams"
  },
  {
      "name": "Kelsey",
      "location": "New York",
      "bookTitle": "The Skin I'm In",
      "author": "Sharon Flake"
  },
  {
      "name": "Kendall",
      "location": "New York",
      "bookTitle": "Song of Solomon",
      "author": "Toni Morrison"
  },
  {
      "name": "Khristi",
      "location": "India",
      "bookTitle": "The Pearl",
      "author": "John Steinbeck"
  },
  {
      "name": "Laura",
      "location": "Florida",
      "bookTitle": "The Emperor's New Clothes",
      "author": "Hans Christian Andersen"
  },
  {
      "name": "Lee",
      "location": "Mississippi",
      "bookTitle": "The Handmaid's Tale",
      "author": "Margaret Atwood"
  },
  {
      "name": "Letitia",
      "location": "Florida",
      "bookTitle": "The Crucible",
      "author": "Arthur Miller"
  },
  {
      "name": "Lettie",
      "location": "Arkansas",
      "bookTitle": "A Marriage Proposal",
      "author": "Anton Chekhov"
  },
  {
      "name": "Li",
      "location": "China",
      "bookTitle": "The Outsiders",
      "author": "S. E. Hinton"
  },
  {
      "name": "Lisa",
      "location": "Kentucky",
      "bookTitle": "The Giver",
      "author": "Lois Lowry"
  },
  {
      "name": "Maci",
      "location": "Illinois",
      "bookTitle": "Riding Freedom",
      "author": "Pam Muñoz Ryan"
  },
  {
      "name": "Maeve",
      "location": "Massachusetts",
      "bookTitle": "Wuthering Heights",
      "author": "Emily Brontë"
  },
  {
      "name": "Makae",
      "location": "Florida",
      "bookTitle": "Ella Enchanted",
      "author": "Gail Carson Levine"
  },
  {
      "name": "Mandy",
      "location": "California",
      "bookTitle": "Adventures on Amelia Island",
      "author": "Jane R. Wood"
  },
  {
      "name": "Mandy",
      "location": "New York",
      "bookTitle": "The Hate U Give",
      "author": "Angie Thomas"
  },
  {
      "name": "Mark",
      "location": "California",
      "bookTitle": "A Wrinkle In Time",
      "author": "Madeleine L'Engle"
  },
  {
      "name": "Mary",
      "location": "Indiana",
      "bookTitle": "Freedom Walkers",
      "author": "Russell Freedman"
  },
  {
      "name": "Megan",
      "location": "Illinois",
      "bookTitle": "The Shakespeare Stealer",
      "author": "Gary Blackwood"
  },
  {
      "name": "Mel",
      "location": "Newfoundland",
      "bookTitle": "Pride and Prejudice",
      "author": "Jane Austen"
  },
  {
      "name": "Michael",
      "location": "Illinois",
      "bookTitle": "Seedfolks",
      "author": "Paul Fleischman"
  },
  {
      "name": "Miranda",
      "location": "California",
      "bookTitle": "A Streetcar Named Desire",
      "author": "Tennessee Williams"
  },
  {
      "name": "Molly",
      "location": "Puerto Rico",
      "bookTitle": "Oliver Twist",
      "author": "Charles Dickens"
  },
  {
      "name": "Nancy",
      "location": "New York",
      "bookTitle": "Hatchet",
      "author": "Gary Paulsen"
  },
  {
      "name": "Nancy",
      "location": "California",
      "bookTitle": "The Adventures of Tom Sawyer",
      "author": "Mark Twain"
  },
  {
      "name": "Natalie",
      "location": "New Hampshire",
      "bookTitle": "Harry Potter and the Half-Blood Prince",
      "author": "J.K. Rowling"
  },
  {
      "name": "Nathan",
      "location": "Massachusetts",
      "bookTitle": "Tuck Everlasting",
      "author": "Natalie Babbitt"
  },
  {
      "name": "Nayantara",
      "location": "Illinois",
      "bookTitle": "The Red Umbrella",
      "author": "Christina Diaz Gonzalez"
  },
  {
      "name": "Nicholas",
      "location": "California",
      "bookTitle": "A Tale of Two Cities",
      "author": "Charles Dickens"
  },
  {
      "name": "Nicholas",
      "location": "Michigan",
      "bookTitle": "The Great Gatsby",
      "author": "F. Scott Fitzgerald"
  },
  {
      "name": "Nicole",
      "location": "Minnesota",
      "bookTitle": "Hamlet",
      "author": "William Shakespeare"
  },
  {
      "name": "Nicole",
      "location": "Saskatchewan",
      "bookTitle": "Red Kayak",
      "author": "Priscilla Cummings"
  },
  {
      "name": "Nika",
      "location": "Rhode Island",
      "bookTitle": "Mary Poppins",
      "author": "P.L. Travers"
  },
  {
      "name": "Nina",
      "location": "New Jersey",
      "bookTitle": "Harry Potter and the Order of the Phoenix",
      "author": "JK Rowling"
  },
  {
      "name": "Nishant",
      "location": "Florida",
      "bookTitle": "Esperanza Rising",
      "author": "Pam Muñoz Ryan"
  },
  {
      "name": "Noelle",
      "location": "Illinois",
      "bookTitle": "Silas Marner",
      "author": "George Eliot"
  },
  {
      "name": "Nur",
      "location": "Idaho",
      "bookTitle": "Freak the Mighty",
      "author": "Rodman Philbrick"
  },
  {
      "name": "Pam",
      "location": "Virginia",
      "bookTitle": "Number the Stars",
      "author": "Lois Lowry"
  },
  {
      "name": "Pari",
      "location": "Connecticut",
      "bookTitle": "Brown Girl Dreaming",
      "author": "Jacqueline Woodson"
  },
  {
      "name": "Rachna",
      "location": "California",
      "bookTitle": "A Single Shard",
      "author": "Linda Sue Park"
  },
  {
      "name": "Rebecca",
      "location": "Maryland",
      "bookTitle": "Granny Torrelli Makes Soup",
      "author": "Sharon Creech"
  },
  {
      "name": "Rishi",
      "location": "Illinois",
      "bookTitle": "The Scarlet Letter",
      "author": "Nathaniel Hawthorne"
  },
  {
      "name": "Sakinah",
      "location": "Illinois",
      "bookTitle": "The Red Pyramid",
      "author": "Rick Riordan"
  },
  {
      "name": "Samson",
      "location": "Quebec",
      "bookTitle": "The Red Badge of Courage",
      "author": "Stephen Crane"
  },
  {
      "name": "Sandra",
      "location": "Florida",
      "bookTitle": "Farewell to Manzanar",
      "author": "Jeanne Wakatsuki"
  },
  {
      "name": "Sandra",
      "location": "Illinois",
      "bookTitle": "Rikki-Tikki-Tavi",
      "author": "Rudyard Kipling"
  },
  {
      "name": "Sara",
      "location": "California",
      "bookTitle": "Antigone",
      "author": "Sophocles"
  },
  {
      "name": "Sara",
      "location": "New York",
      "bookTitle": "The House on Mango Street",
      "author": "Sandra Cisneros"
  },
  {
      "name": "Sarah",
      "location": "California",
      "bookTitle": "The Black Stallion",
      "author": "Walter Farley"
  },
  {
      "name": "Shezal",
      "location": "Austrailia",
      "bookTitle": "Out of My Mind",
      "author": "Sharon Draper"
  },
  {
      "name": "Sonia",
      "location": "Arizona",
      "bookTitle": "A Mango-Shaped Space",
      "author": "Wendy Mass"
  },
  {
      "name": "Sophia",
      "location": "Massachusetts",
      "bookTitle": "When the Emperor Was Divine",
      "author": "Julie Otsuka"
  },
  {
      "name": "Sophie",
      "location": "Massachusetts",
      "bookTitle": "Touching Spirit Bear",
      "author": "Ben Mikaelsen"
  },
  {
      "name": "Stella",
      "location": "British Columbia",
      "bookTitle": "Peace Like a River",
      "author": "Leif Enger"
  },
  {
      "name": "Stella",
      "location": "Illinois",
      "bookTitle": "Sarah Plain and Tall",
      "author": "Patricia MacLachlan"
  },
  {
      "name": "Sue",
      "location": "California",
      "bookTitle": "Animal Dreams",
      "author": "Barbara Kingsolver"
  },
  {
      "name": "Sue",
      "location": "New York",
      "bookTitle": "Henry V",
      "author": "William Shakespeare"
  },
  {
      "name": "Tenera",
      "location": "Florida",
      "bookTitle": "Fever 1793",
      "author": "Laurie Halse Anderson"
  },
  {
      "name": "Thomas",
      "location": "California",
      "bookTitle": "Beowulf",
      "author": "Anonymous"
  },
  {
      "name": "Timothy",
      "location": "Alberta",
      "bookTitle": "Peter Pan",
      "author": "J.M. Barrie"
  },
  {
      "name": "Tina",
      "location": "Maine",
      "bookTitle": "Going Solo",
      "author": "Roald Dahl"
  },
  {
      "name": "Twins",
      "location": "Massachusetts",
      "bookTitle": "Great Expectations",
      "author": "Charles Dickens"
  },
  {
      "name": "Ty",
      "location": "Delaware",
      "bookTitle": "The Cask of Amontillado",
      "author": "Edgar Allan Poe"
  },
  {
      "name": "Victoria",
      "location": "Pennsylvania",
      "bookTitle": "The Martian Chronicles",
      "author": "Ray Bradbury"
  },
  {
      "name": "Yashira",
      "location": "Tennessee",
      "bookTitle": "My Side of the Mountain",
      "author": "Jean Craighead George"
  },
  {
      "name": "Yifei",
      "location": "New York",
      "bookTitle": "The Tiger Rising",
      "author": "Kate DiCamillo"
  },
  {
      "name": "Zuri",
      "location": "Illinois",
      "bookTitle": "Red Scarf Girl",
      "author": "Ji-Li Jiang"
  }
]
const name = []
const location = []
const bookTitle = []
const author = []
TEAM.forEach(element => {
name.push(element.name, 8000)
location.push(element.location, 8000)
bookTitle.push(element.bookTitle, 8000)
author.push(element.author, 8000)
});

export const BannerMobile = () => {
  const { desktop, mobile } = useStyles();
  const [track, setTrack] = useState(100);

  useEffect(() => {
    const interval = setInterval(() => setTrack(Math.floor((Math.random()*150)+1)), 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);
  return (
    <>
        <Box pt={0} px={3}>

        <Box backgroundColor = '' pt={7} pb={5} width="100%">
           <Typography  pb={3} variant="h4" color="#272D4E" textAlign="center">
           Thousands of Students are Learning on Word Voyage!
            </Typography>
            
            <Typography variant="h6" color="#272D4E" textAlign="center">
            <p>{TEAM[track].name +  ' in ' + TEAM[track].location + ' is studying vocabulary from '}<span style={{color: "#FFCD00"}}>{TEAM[track].bookTitle}</span> {' by ' + TEAM[track].author}</p>

            </Typography>
        </Box>
        </Box>
    </>
  );
};
