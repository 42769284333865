import { Footer } from "../footer";
import React from "react";
import { AboutIntroduction } from "./about-introduction";
import { Team } from "./about-team";

export const About = () => {
  return (
    <>
      <AboutIntroduction />
      <Team />
      <Footer />
    </>
  );
};
