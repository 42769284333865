import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import Tutoring from "../../assets/girl-home-4.jpeg";

export const TutoringContent = () => {
  const { palette } = useTheme();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <>
      <Typography variant="h3" 
        style={{
          paddingBottom: mobile ? "56px" : "25px"
        }}>VOCABULARY METHOD</Typography>
      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Vocabulary from Literature
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
      >
        The student will study words from current and upcoming literature assignments. 
        Word Voyage includes lessons from over 
        1300 <a href="http://www.wordvoyage.com/docs/literature_list_library2.pdf"
                target="_blank">classic and popular titles</a>.
      </Typography>
      <br/>
      <img
        width="100%"
        height="auto"
        src={Tutoring}
        alt={"Literature-Based Vocabulary"}
        loading="lazy"
        style={{ paddingBottom: mobile ? "56px" : "56px" }}
      />

      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Latin and Greek Roots
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "56px" : "56px" }}
      >
        The lessons provide extensive practice with word roots and meaning formation. 
        The student learns to slow down and analyze unfamiliar words, a critical strategy for 
        improving reading comprehension. This method provides strong preparation for <span style={{fontWeight: "600", color: "black"}}>ISEE</span> and other 
        standardized tests.
      </Typography>

      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Word Mastery from Exercises, Games, and Original Writing
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "56px" : "80px" }}
      >
        The student is challenged to drag root cards to build meanings, relate root-level meanings 
        to modern meanings, build root families, practice spellings, divide up syllables, investigate 
        words in context, and identify parts of speech. And we don't stop there. The student must also 
        demonstrate the proper usage of each word in an <span style={{fontWeight: "600", color: "black"}}>original sentence</span>. This exercise challenges 
        the student to <span style={{fontWeight: "600", color: "black"}}>pull new knowledge together</span>, and provides the perfect opportunity for the 
        tutor to deliver formative feedback. All sentences must demonstrate word mastery before the 
        student is moved on to the next level.
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: mobile ? "56px" : "25px" }}
      >
        GRAMMAR METHOD
      </Typography>
      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Grammar Scope and Sequence
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "16px" : "28px" }}
      >
        As part of the sentence-writing project, the student must also meet specific grammar requirements. 
        Following an assessment by the tutor, the student is placed on 
        the <a href="http://www.wordvoyage.com/docs/grammar_curriculum.pdf" 
        target="_blank">Word Voyage Grammar Scope and Sequence</a> and works up from there. <span style={{fontWeight: "600", color: "black"}}>Word Voyage 
        students write hundreds of sentences</span>. Each new level reinforces previously-studied skills.
      </Typography>

      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Instructional Videos
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "56px" : "56px" }}
      >
        The tutor assigns videos to 
        introduce each new skill and to review old ones. 
        Examples: <a href="http://www.wordvoyage.com/videos/student/complete_sentences/complete_sentences.mp4" 
        target="_blank">Complete Sentences</a>, <a href="http://www.wordvoyage.com/videos/student/prepositions/prepositions.mp4" 
        target="_blank">Prepositions</a>, <a href="http://www.wordvoyage.com/videos/student/adjectives/adjectives.mp4" 
        target="_blank">Adjectives</a>, <a href="http://www.wordvoyage.com/videos/student/linking_verbs/linking_verbs.mp4" 
        target="_blank">Linking Verbs</a>, <a href="http://www.wordvoyage.com/videos/student/phrases_clauses/phrases_clauses.mp4" 
        target="_blank">Phrases and Clauses</a>, <a href="http://www.wordvoyage.com/videos/student/noun_jobs/noun_jobs.mp4" 
        target="_blank">Noun Jobs</a>, <a href="http://www.wordvoyage.com/videos/student/gerunds/gerunds.mp4" target="_blank">Gerunds</a>.
      </Typography>

      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Sentence Formatting
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "16px" : "28px" }}
      >
        The student is directed to use formatting tools to <span style={{fontWeight: "600", color: "black"}}>identify specific sentence components</span>. 
        For example: bold the adjective and underline the word described by the adjective. 
        Or: underline the dependent clause, bold the conjunction, and italicize the independent clause. 
        This process makes the student aware of each sentence feature and the function it performs.
      </Typography>

      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Targeted Feedback and Revision Process
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
      >
        The tutor carefully evaluates each sentence and <span style={{fontWeight: "600", color: "black"}}>guides the student through the revision process</span>. 
        In the first few weeks, it is common for sentences to require 3 or 4 rounds of revision. 
        However, once the student gets in the habit of proofreading, the number of revisions goes down and 
        more new skills are introduced. The tutor is always available for questions.
      </Typography>

      <Typography
        variant="h3"
        style={{
          paddingBottom: mobile ? "56px" : "25px",
          paddingTop: mobile ? "56px" : "80px",
        }}
      >
        GRADE LEVEL DETAILS
      </Typography>

      <Typography
        variant="h4"
        color="#34566A"
        style={{ paddingBottom: mobile ? "16px" : "10px" }}
      >
        Upper Elementary
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage provides direct instruction for young readers moving into 4th and 5th grade-level 
        reading content. To learn more about why this instruction is so critical, please see: 
        <a href="http://www.wordvoyage.com/docs/white_paper,nh.pdf" target="_blank"> Why do 4th and 5th 
        graders need to study roots?</a> On the grammar side, our tutors work with students 
        on complete sentences, sentence types, parts of speech, phrases, and clauses. Establishing a solid 
        foundation with this skill set prepares the student for Middle School curriculum and standardized tests.
      </Typography>

      <Typography
        variant="h4"
        color="#34566A"
        style={{
          paddingBottom: mobile ? "16px" : "10px",
          paddingTop: mobile ? "56px" : "64px",
        }}
      >
        Middle School
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        While this is understandably a time of great distraction, it is also the
        time when <span style={{fontWeight: "600", color: "black"}}>fundamental literacy skills must be nailed down!</span> Direct
        instruction from a Word Voyage tutor is an efficient and highly
        effective way to meet this challenge. The student can study vocabulary
        from current reading assignments, words that are relevant and needed
        now! Exercises and games deliver extensive practice with word roots,
        root families (build vocabulary exponentially!), modern definitions, and
        proper usage. Our tutoring also focuses the student on <span style={{fontWeight: "600", color: "black"}}>attention to
        writing details</span> and learning to deploy proper grammar in the first
        draft.
      </Typography>

      <Typography
        variant="h4"
        color="#34566A"
        style={{
          paddingBottom: mobile ? "16px" : "10px",
          paddingTop: mobile ? "56px" : "64px",
        }}
      >
        High School
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage tutors <span style={{fontWeight: "600", color: "black"}}>target the skill gaps that are holding students back</span>
        with reading and writing assignments, as well as on 
        the <a href="http://www.wordvoyage.com/docs/rewindingandreinforcing.pdf" target="_blank">SAT or ACT</a>. 
        Many times students are "in the ballpark" with vocabulary and grammar skills
        and just need more regular, focused time-on-task. To be successful and
        meet their goals, <span style={{fontWeight: "600", color: "black"}}>students must be able to skillfully represent
        themselves in writing</span>. Nothing undermines this effort like grammar and
        word-usage errors. Word Voyage tutors provide the key practice and
        feedback young writers need to solidify their skills. Details matter!
      </Typography>
    </>
  );
};
