import { Box, Button, Typography } from "@mui/material";
import { makeStyles, useTheme } from "@mui/styles";
import React, { forwardRef, useRef } from "react";

const useStyles = makeStyles({
  stickyContainer: {
    position: "sticky",
    top: 50,
  },
  tabs: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    borderLeft: "2px solid #CBD0D3",
    paddingLeft: "26px",
    paddingTop: "12px",
    paddingBottom: "12px",
  },
});

const Tab = forwardRef(({ isOnElement, label, removeMargin }, ref) => {
  const { palette } = useTheme();
  return (
    <Box position="relative" mb={removeMargin ? "0px" : "40px"}>
      <Box
        bgcolor={isOnElement ? "#34566A" : "transparent"}
        style={{
          borderRadius: "4px",
          height: (ref.current?.clientHeight || 40) + 25,
          width: "4px",
          position: "absolute",
          left: -29.5,
          top: -12,
        }}
      />
      <Box ref={ref}>
        <Typography
          variant="body2"
          color={isOnElement ? "#34566A" : palette.grey.main}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
});

export const SchoolBodyGradeSixToEightTabs = ({ percentage }) => {
  const { tabs, stickyContainer } = useStyles();

  const makeVocabRelevantRef = useRef(null);
  const improvesReadingComprehensionRef = useRef(null);
  const keepTheFocusOnWritingRef = useRef(null);
  const moveGrammerSkillsForwardRef = useRef(null);
  const provideRegularFormativeFeedbackRef = useRef(null);
  const differentiateInstructionRef = useRef(null);
  const manageLoadsRef = useRef(null);

  const isOnMakeVocabRelevant = percentage < 0.35;
  const isOnImprovesReadingComprehension =
    (percentage > 0.35) & (percentage < 0.46);
  const isOnKeepTheFocusOnWriting = (percentage > 0.46) & (percentage < 0.53);
  const isOnMoveGrammarSkillsForward = (percentage > 0.53) & (percentage < 0.6);
  const isOnRegularFeedback = percentage > 0.6 && percentage < 0.66;
  const isOnDifferentiateInstruction = percentage > 0.66 && percentage < 0.7;
  const isOnManageLoadsRef = percentage > 0.7;

  return (
    <Box className={stickyContainer}>
      <Box className={tabs}>
        <Tab
          label="Make Vocabulary Relevant"
          ref={makeVocabRelevantRef}
          isOnElement={isOnMakeVocabRelevant}
        />
        <Tab
          label="Improves Reading Comprehension"
          ref={improvesReadingComprehensionRef}
          isOnElement={isOnImprovesReadingComprehension}
        />
        <Tab
          label="Keep the Focus on Writing"
          ref={keepTheFocusOnWritingRef}
          isOnElement={isOnKeepTheFocusOnWriting}
        />
        <Tab
          label="Move Grammar Skills Forward"
          ref={moveGrammerSkillsForwardRef}
          isOnElement={isOnMoveGrammarSkillsForward}
        />
        <Tab
          label="Provide Regular Formative Feedback"
          ref={provideRegularFormativeFeedbackRef}
          isOnElement={isOnRegularFeedback}
        />
        <Tab
          label="Differentiate Instruction"
          ref={differentiateInstructionRef}
          isOnElement={isOnDifferentiateInstruction}
        />
        <Tab
          removeMargin
          label="Manage Large Student Loads"
          ref={manageLoadsRef}
          isOnElement={isOnManageLoadsRef}
        />
      </Box>

      <Box pt={6}>
        <Button
          variant="contained"
          fullWidth
          style={{
            borderRadius: "32px",
            backgroundColor: "#34566A",
            color: "white",
            paddingRight: "42px",
            paddingLeft: "42px",
            padding: "14px 42px 14px 42px",
          }}
          onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLSeqS4_hIhsYqfpmGUjCCe9fRRgsJM-80_Yp1CRLzqTWs1R-TA/viewform?c=0&w=1", "_blank")}
        >
          Get a quote
        </Button>
      </Box>
    </Box>
  );
};
