import { useTheme } from "@emotion/react";
import { Box, Container, Typography } from "@mui/material";
import React from "react";
import {
  BuildYourVocabularyLogo,
  ImproveReadingLogo,
  RaiseTestScoresLogo,
  WordRootsLogo,
} from "../../icons";
export const MethodologyMobile = () => {
  const { palette } = useTheme();
  return (
    <Box minHeight="100vh" position="relative" bgcolor="#FAFAFA">
      <Container maxWidth="sm">
        <Box pt={8} pb={8}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box>
              <Typography variant="h4" color={palette.common.black}>
                Our Teaching Methodology
              </Typography>
            </Box>
            <Box pt={2}>
              <Box
                bgcolor={palette.secondary.main}
                border={`4px solid ${palette.secondary.main}`}
                width="90px"
                borderRadius="4px"
              />
            </Box>
            <Box
              pt={6}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <WordRootsLogo />
              <Box pt={4} pb="12px">
                <Typography variant="h4" color="#272D4E">
                  Learn Word Roots
                </Typography>
              </Box>
              <Box pt={2} pb={6}>
                <Typography
                  variant="body4"
                  color="#737B7D"
                  component="div"
                  textAlign="center"
                >
                  Over 90% of English words have Latin or Greek roots. These are
                  often high-priority vocabulary.
                </Typography>
              </Box>
              <BuildYourVocabularyLogo />
              <Box pt={4} pb="12px">
                <Typography variant="h4" color="#272D4E">
                  Build Your Vocabulary
                </Typography>
              </Box>
              <Box pt={2} pb={6}>
                <Typography
                  component="div"
                  textAlign="center"
                  variant="body4"
                  color="#737B7D"
                >
                  Word Voyage students master root families and build vocabulary
                  exponentially!
                </Typography>
              </Box>
              <ImproveReadingLogo />
              <Box pt={4} pb="12px">
                <Typography
                  variant="h4"
                  component="div"
                  textAlign="center"
                  color="#272D4E"
                >
                  Improve Reading Comprehension
                </Typography>
              </Box>
              <Box pt={2} pb={6}>
                <Typography
                  component="div"
                  textAlign="center"
                  variant="body4"
                  color="#737B7D"
                >
                  Word Voyage students learn to analyze unfamiliar words, rather
                  than skip over them.
                </Typography>
              </Box>
              <RaiseTestScoresLogo />
              <Box pt={6}>
                <Typography variant="h4" color="#272D4E">
                  Raise Test Scores
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography
                  variant="body4"
                  color="#737B7D"
                  component="div"
                  textAlign="center"
                >
                  Root words make up the vast majority of the vocabulary on
                  standardized tests.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
