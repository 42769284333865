import { useTheme } from "@emotion/react";
import { Box, Button, Typography, Grid, Menu, MenuItem } from "@mui/material";
import { green } from "@mui/material/colors";
import { makeStyles, withStyles } from "@mui/styles";
import React, { useState } from "react";
import { RightChevronIcon } from "../icons";

const StyledMenu = withStyles({
  root: { "& .MuiMenu-list": { padding: 0 } },
  paper: {
    border: "1px solid #d3d4d5",
    borderRadius: 0,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    transformOrigin={{
      horizontal: -126,
      vertical: 20,
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const SchoolIntroduction = ({ grade, setGrade }) => {
  const { palette } = useTheme();
  const { desktop, mobile } = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const resetBtn = () => {
    const nodeList = document.querySelectorAll(".gradeBtn");
    for (let i = 0; i < nodeList.length; i++) {
      nodeList[i].style.color = "black";
      // nodeList[i].style.backgroundColor = "#FFCD00";
    }
  }

  const handleGrade4to5 = (e) => {
    resetBtn()
      var testContainer = e.target.parentElement;
     testContainer.querySelector('p.gradeBtn').style.color = 'white';
    setGrade("4-5");
    handleClose();
  };
  const handleGrade6to8 = (e) => {
    resetBtn()
    var testContainer = e.target.parentElement;
    testContainer.querySelector('p.gradeBtn').style.color = 'white';
    setGrade("6-8");
    handleClose();
  };
  const handleGrade9to12 = (e) => {
    resetBtn()
    var testContainer = e.target.parentElement;
    testContainer.querySelector('p.gradeBtn').style.color = 'white';
    setGrade("9-12");
    handleClose();
  };
  return (
    <>
      <Box px={12} pt={10} pb={10} className={desktop}>
        <Box width="50%">
          <Box display="flex" pb="16px">
            <Box maxWidth="100%" display="flex">
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                   <Button
                     variant="contained"
                     fullWidth
                     style={{
                       borderRadius: "32px",
                       backgroundColor: "#FFCD00",
                       color: "#34566A",
                       padding: "10px",
                       marginRight: "40px",
                       border: 'none',
                       minWidth: '110px'
                     }}
                     className="gradeBtn"
                     onClick={handleGrade4to5} 
                     >
                     <Typography
                     className="gradeBtn"
                       component="p"
                       variant="button"
                       color="#34566A"
                       style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                     >
                    Grades 4-5
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                   <Button
                     variant="contained"
                     fullWidth
                     style={{
                       borderRadius: "32px",
                       backgroundColor: "#FFCD00",
                       color: "#34566A",
                       padding: "10px",
                       border: 'none',
                       minWidth: '110px'
                     }}
                     className="gradeBtn"
                     onClick={handleGrade6to8}
                     >
                     <Typography
                     className="gradeBtn"
                       component="p"
                       variant="button"
                       color="#34566A"
                       style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                     >
                    
                    
                    Grades 6-8
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                   <Button
                     variant="contained"
                     fullWidth
                     style={{
                       borderRadius: "32px",
                       backgroundColor: "#FFCD00",
                       color: "#34566A",
                       padding: "10px",
                       marginLeft: "40px",
                       border: 'none',
                       minWidth: '110px'
                     }}

                     className="gradeBtn"
                     onClick={handleGrade9to12} 
                     >
                     <Typography
                     className="gradeBtn"
                       component="p"
                       variant="button"
                       color="#34566A"
                       style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                     >
                    
                    
                    Grades 9-12
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* <Box
              display="flex"
              alignItems="center"
              style={{ cursor: "pointer" }}
              onClick={handleClick}
            >
              <Typography
                variant="body2"
                color="#FFCD00"
                component="span"
                style={{ paddingRight: "12px" }}
              >
                Grades {grade}
              </Typography>
              <RightChevronIcon />
            </Box> */}
          </Box>
          {/* <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              style={{ borderBottom: "1px solid #CBD0D3" }}
              onClick={handleGrade4to5}
            >
              <Typography
                variant="button1"
                style={{ paddingBottom: "6px", paddingTop: "6px" }}
              >
                Grades 4-5
              </Typography>
            </MenuItem>
            <MenuItem
              style={{ borderBottom: "1px solid #CBD0D3" }}
              onClick={handleGrade6to8}
            >
              <Typography
                variant="button1"
                style={{ paddingBottom: "6px", paddingTop: "6px" }}
              >
                Grades 6-8
              </Typography>
            </MenuItem>
            <MenuItem onClick={handleGrade9to12}>
              <Typography
                variant="button1"
                style={{ paddingBottom: "6px", paddingTop: "6px" }}
              >
                Grades 9-12
              </Typography>
            </MenuItem>
          </StyledMenu> */}
          <Typography variant="h1" color={palette.common.white}>
            {grade === "4-5" && "Upper Elementary School"}
            {grade === "6-8" && "Middle School"}
            {grade === "9-12" && "High School"}
          </Typography>
        </Box>
        <Box width="70%" pt={2}>
          <Typography variant="body3" component="p" color={palette.common.white}>
            {grade === "4-5" && "As students move into 4th and 5th grade, their exposure to challenging " + 
            "vocabulary increases exponentially. These are often high priority words that describe " + 
            "characters, scenes, moods - words that cannot be passed over! Word Voyage guides students " + 
            "into this new world of words, while also continuing with spelling, syllables, grammar, " + 
            "and writing mechanics."}

            {grade === "6-8" && "Word Voyage is the ideal tool for Middle School English teachers! "
            + "The program offers curriculum-based vocabulary, word roots, grammar "
            + "and sentence construction in a single platform - a highly efficient "
            + "solution that also provides for differentiated instruction!"}

            {grade === "9-12" && "Word Voyage is the ideal tool for High School English teachers! "
            + "The program offers curriculum-based vocabulary, word roots, grammar "
            + "and sentence construction in a single platform - a highly efficient "
            + "solution that also provides for differentiated instruction!"}
          </Typography>
        </Box>
      </Box>

      <Box className={mobile} pt={8} pb={6}>
        <Box display="flex" alignItems="center">
        <Box maxWidth="100%" display="flex">
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                   <Button
                     variant="contained"
                     fullWidth
                     style={{
                       borderRadius: "32px",
                       backgroundColor: "#FFCD00",
                       color: "#34566A",
                       padding: "10px",
                       marginRight: "20px",
                       border: 'none',
                       minWidth: '110px'
                     }}
                     className="gradeBtn"
                     onClick={handleGrade4to5} 
                     >
                     <Typography
                     className="gradeBtn"
                       component="p"
                       variant="button"
                       color="#34566A"
                       style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                     >
                    Grades 4-5
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                   <Button
                     variant="contained"
                     fullWidth
                     style={{
                       borderRadius: "32px",
                       backgroundColor: "#FFCD00",
                       color: "#34566A",
                       padding: "10px",
                       border: 'none',
                       minWidth: '110px'
                     }}
                     className="gradeBtn"
                     onClick={handleGrade6to8}
                     >
                     <Typography
                     className="gradeBtn"
                       component="p"
                       variant="button"
                       color="#34566A"
                       style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                     >
                    
                    
                    Grades 6-8
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                   <Button
                     variant="contained"
                     fullWidth
                     style={{
                       borderRadius: "32px",
                       backgroundColor: "#FFCD00",
                       color: "#34566A",
                       padding: "10px",
                       marginLeft: "20px",
                       border: 'none',
                       minWidth: '110px'
                     }}
                     className="gradeBtn"
                     onClick={handleGrade9to12} 
                     >
                     <Typography
                     className="gradeBtn"
                       component="p"
                       variant="button"
                       color="#34566A"
                       style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                     >
                    
                    
                    Grades 9-12
                      </Typography>
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          {/* <Typography
            variant="body2"
            color="#FFCD00"
            style={{ paddingRight: "12px" }}
          >
            Grades {grade}
          </Typography>
          <RightChevronIcon /> */}
        </Box>
        {/* <StyledMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            style={{ borderBottom: "1px solid #CBD0D3" }}
            onClick={handleGrade4to5}
          >
            <Typography
              variant="button1"
              style={{ paddingBottom: "6px", paddingTop: "6px" }}
            >
              Grades 4-5
            </Typography>
          </MenuItem>
          <MenuItem
            style={{ borderBottom: "1px solid #CBD0D3" }}
            onClick={handleGrade6to8}
          >
            <Typography
              variant="button1"
              style={{ paddingBottom: "6px", paddingTop: "6px" }}
            >
              Grades 6-8
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleGrade9to12}>
            <Typography
              variant="button1"
              style={{ paddingBottom: "6px", paddingTop: "6px" }}
            >
              Grades 9-12
            </Typography>
          </MenuItem>
        </StyledMenu> */}
        <Typography
          variant="h4"
          color={palette.common.white}
          style={{ paddingTop: "16px", paddingBottom: "16px" }}
        >
          {grade === "4-5" && "Upper Elementary School"}
          {grade === "6-8" && "Middle School"}
          {grade === "9-12" && "High School"}
        </Typography>
        <Typography variant="body4" color={palette.common.white}>
          Word Voyage is the ideal tool for Middle School English teachers! The
          program offers curriculum-based vocabulary, word roots, grammar, and
          sentence construction in a single platform- a highly efficient
          solution that also provides for differentiated instruction!
        </Typography>
      </Box>
    </>
  );
};
