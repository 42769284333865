import { Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";
import LiteratureBasedVocab from "../../assets/literature-based-vocabulary.png";

export const SchoolBodyGradeFourToFiveContent = () => {
  const { palette } = useTheme();
  const mobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <>
      <Typography variant="h3">LITERATURE-BASED VOCABULARY</Typography>

      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: mobile ? "56px" : "80px", paddingTop: "32px" }}
      >
        Our students study words sourced from their current reading assignments.
        Word Voyage includes hundreds of lists from classic and popular titles, 
        plus a service to add additional lists on request.
      </Typography>
      <img
        width="100%"
        height="auto"
        src={LiteratureBasedVocab}
        alt={"Literature-Based Vocabulary"}
        loading="lazy"
      />
      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        LATIN AND GREEK ROOTS
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: "16px"}}
      >
        Why do 4th and 5th graders need to study word roots? Well, to begin
        with, most multisyllabic words in English have them. Secondly, as
        explained by linguist Dr. Abigail Konopasky, exposure to root words
        increases exponentially during grades 4 and 5. From a linguistic
        standpoint, these students are <span style={{fontWeight: "600", color: "black"}}>taking on a new language within English</span>,
        moving from the Germanic word family to the Romance word family. For
        example, a student reading <span style={{fontWeight: "600", color: "black"}}>Charlotte’s Web</span> must navigate perspiration,
        commotion, endure, approximate, salutations, gullible, exertion, and
        many other highly descriptive root words. A student reading <span style={{fontWeight: "600", color: "black"}}>Number the
        Stars</span>, commonly taught in grade 5, must make sense of <i>exasperated,
        imperious, belligerent, impassive, residential, intricate, designate,</i> and many others.  <span style={{fontWeight: "600", color: "black"}}>The root words have arrived!</span>
      </Typography>
      <Typography
        variant="body1"
        color={palette.grey.main}
        style={{ paddingBottom: "16px" }}
      >
        In the K-3 years, there is a large emphasis on memorizing spelling
        patterns, spelling rules, and irregular words. In addition, meanings are
        often easily revealed by context clues. Root words change the game: the
        meanings are more intricate and diverse. Context clues can help, but
        they are often not enough. The students must <span style={{fontWeight: "600", color: "black"}}>look inside the language
        for the key clues that unpack meanings</span>! Memorizing must give way to <i style={{color: "#FFCD00"}}>analyzing</i>.
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage provides extensive practice with a word-attack strategy we
        call <span style={{fontWeight: "600", color: "black"}}>Slow Down, Break It Down</span>. Rather than shy away from strange words,
        our students learn to go after them, pick apart the roots, relate known
        words to unfamiliar ones, and in the process, <span style={{fontWeight: "600", color: "black"}}>become far more careful
        readers</span>!
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        WORD MASTERY FROM EXERCISES
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage offers a variety of exercises and games that help students
        build durable vocabularies. They can drag root cards to build meanings,
        relate root-level meanings to modern meanings, build root families,
        practice spellings, divide up syllables, investigate words in context,
        and identify parts of speech. I addition, Word Voyage asks the students
        to try out their new words in original sentences. This exercise
        challenges the student to <span style={{fontWeight: "600", color: "black"}}>pull new knowledge together in their own
        authentic writing</span>.
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        GRAMMAR AND SENTENCE CONSTRUCTION
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        The Word Voyage Sentence-Writing platform can also be used to teach
        grammar! The program includes dozens of preset grammar lessons following
        a detailed scope and sequence beginning at the 4th-grade level. Teachers
        can customize the lessons to cover the skills their students need, and
        provide abundant practice to make sure no details slip through the
        cracks.
      </Typography>

      <Typography
        variant="h3"
        style={{ paddingBottom: "24px", paddingTop: mobile ? "56px" : "80px" }}
      >
        ONE ON ONE GUIDANCE
      </Typography>

      <Typography variant="body1" color={palette.grey.main}>
        Word Voyage teachers provide formative feedback on word usage and
        grammar. As powerful as our instructional methods are, we know that
        technology can never replace the teacher, especially when it comes to
        evaluating writing! Our platform provides the leverage and convenience
        teachers need to deliver regular, one-on-one guidance to each student.
      </Typography>
    </>
  );
};
