import { Box, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useTheme } from "@emotion/react";
import TestimonialBackgroundImage from "../../../assets/testimonial-background-image.png";
import { StarIcon } from "../../icons";

const useStyles = makeStyles((theme) => ({
  testimonialContainer: {
    background: `url(${TestimonialBackgroundImage}) no-repeat center center fixed`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  marqueeContainer: {
    paddingTop: theme.spacing(15),
    paddingBottom: "193px",
  },
  marquee: {
    flex: "0 0 auto",
    zIndex: 1,
    animation: "$slide 90s infinite linear",
    display: "flex",
    minWidth: "100%",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down("sm")]: {
      "& > *:not(:last-child)": {
        marginRight: theme.spacing(4),
      },
    },
  },
  "@keyframes slide": {
    "0%": {
      transform: "translateX(0%)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
}));

export const TestimonialDesktop = () => {
  const { palette } = useTheme();
  const styles = useStyles();
  return (
    <Box overflow="hidden" className={styles.testimonialContainer}>
      <Box pt={22}>
        <Box width="100%" display="flex" justifyContent="center">
          <Typography variant="h1" color={palette.common.black}>
            What Our Customers Say
          </Typography>
        </Box>
        <Box width="100%" display="flex" justifyContent="center" pt={2}>
          <Box
            bgcolor={"#FFCD00"}
            height="4px"
            width="90px"
            borderRadius="4px"
          />
        </Box>
      </Box>
      <Box className={styles.marqueeContainer}>
        <Box className={styles.marquee}>
          <Paper style={{ borderRadius: "8px" }}>
            <Box
              width="450px"
              px={3}
              py={3}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box display="flex">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Box>
              <Box pt={2}>
                <Typography variant="body4">
                  I took my daughter to a weekly tutoring session for most of
                  her 6th grade year. Over the last 4 months we've switched to
                  Word Voyage tutoring with wonderful results. For one thing it
                  is much more convenient and the monthly cost is less than the
                  weekly cost at the tutoring center. I like Word Voyage's old
                  school, back to basics approach. On top of this, the tutor has
                  helped her with grammar and sentence writing. I can't
                  recommend Word Voyage enough!
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography variant="body2">Molly P.</Typography>
              </Box>
              <Box>
                <Typography variant="body4">Parent</Typography>
              </Box>
            </Box>
          </Paper>
          <Paper style={{ borderRadius: "8px" }}>
            <Box
              width="450px"
              px={3}
              py={3}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box display="flex">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Box>
              <Box pt={2}>
                <Typography variant="body4">
                  Word Voyage has proven an invaluable tool in my classroom as a
                  first-year middle school English teacher. My students are both
                  engaged and challenged by the programs and their vocabulary
                  has flourished. Not only am I able to personalize our lessons
                  to complement the rest of our English curriculum, but Whit
                  responds with lightning fast speed to any questions or issues
                  that arise. I have nothing but the highest praise for this
                  program and Whit.
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography variant="body2">Laura Micetich</Typography>
              </Box>
              <Box>
                <Typography variant="body4">
                  University School of Jackson, Jackson, TN
                </Typography>
              </Box>
            </Box>
          </Paper>
          <Paper style={{ borderRadius: "8px" }}>
            <Box
              width="450px"
              px={3}
              py={3}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Box display="flex">
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </Box>
              <Box pt={2}>
                <Typography variant="body4">
                  My son just completed 4 months of work with a Word Voyage
                  tutor. The content was definitely challenging for him, but the
                  tutor quickly adjusted the lessons to make them manageable. I
                  loved the instruction in Latin roots and root families- these
                  will be very helpful on the SAT. It's also wonderful to see
                  how much his grammar improved. He's now correcting my grammar
                  all the time! The continual support and positive reinforcement
                  from the tutor was truly remarkable!
                </Typography>
              </Box>
              <Box pt={2}>
                <Typography variant="body2">Cynthia W.</Typography>
              </Box>
              <Box>
                <Typography variant="body4">Parent</Typography>
              </Box>
            </Box>
          </Paper>
          <Box display="flex">
            <Paper style={{ borderRadius: "8px" }}>
              <Box
                width="450px"
                px={3}
                py={3}
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
              >
                <Box display="flex">
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                  <StarIcon />
                </Box>
                <Box pt={2}>
                  <Typography variant="body4">
                    You have been so great with us and we are looking forward to
                    another great year. Guess what? Our ERB Vocabulary scores
                    improved 16% last year!
                  </Typography>
                </Box>
                <Box pt={2}>
                  <Typography variant="body2">Karen Anderson</Typography>
                </Box>
                <Box>
                  <Typography variant="body4">
                    Wise School, Los Angeles, CA
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
