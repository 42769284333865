import { Box, Container, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
// import { useScrollPercentage } from "react-scroll-percentage";
import { Footer } from "../footer";
import { Navbar } from "../header";
import { TutoringContent } from "./tutoring-content";
import { TutoringIntroduction } from "./tutoring-introduction";
import { TutoringTabs } from "./tutoring-tabs";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "100vw",
    backgroundColor: "#34566A",
  },
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
export const Tutoring = () => {
  const { main, desktop, mobile } = useStyles();
  // const [ref, percentage] = useScrollPercentage();
  return (
    <>
      <Box className={desktop}>
        <Box className={main}>
          <Container maxWidth="lg">
            <Navbar />
            <TutoringIntroduction />
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box  px={12} pt={10} pb={10}>
            <Grid container>
              <Grid item xs={3}>
                <TutoringTabs  />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={8}>
                <TutoringContent />
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Footer />
      </Box>

      <Box className={mobile}>
        <Box className={main}>
          <Container maxWidth="lg">
            <Navbar />
            <TutoringIntroduction />
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Box pt={10} pb={10}>
            <TutoringContent />
          </Box>
        </Container>
        <Footer />
      </Box>
    </>
  );
};
