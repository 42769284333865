import { Route, Routes } from "react-router-dom";
import { AboutPage, LandingPage, SchoolPage, TutoringPage, PrivacyPolicyPage } from "./pages";
// import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="about" element={<AboutPage />} />
        <Route path="school" element={<SchoolPage />} />
        <Route path="tutoring" element={<TutoringPage />} />
        <Route path="privacy-policy" element={<PrivacyPolicyPage />} />
      </Routes>
    </div>
  );
}

export default App;
