import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useTheme } from '@emotion/react';
import ReadMoreReact from 'read-more-react';
import AbbyPic from '../../assets/abhishek_sharma.png';
import JavisPic from '../../assets/javis.jpg';
import WhitPic from '../../assets/whit_symmes.png';
import JessicaPic from '../../assets/jessica_ma.png';
import YingPic from '../../assets/ying_song.png';
import AprilPic from '../../assets/april_halacy.png';
import JenniferPic from '../../assets/jennifer_connell_wandstrat.png';
import OliviaPic from '../../assets/olivia_cobian.png';
import SarahPic from '../../assets/sarah_evans_politte.png';
import WitahPic from '../../assets/witah_georjane.png';
import JonathanPic from '../../assets/Jonathan.png';
import YannickPic from '../../assets/yannick.png';
import EmmaPic from '../../assets/Emma.png';
import AbdelPic from '../../assets/abdel_karim_mounkambou.png';
import AashishPic from '../../assets/aashish.png';
import { AboutTeamMobile } from './about-team-mobile';

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));

export const Team = () => {
  const { desktop, mobile } = useStyles();
  const { palette } = useTheme();
  return (
    <>
      <Box className={desktop} position='relative'>
        <Container maxWidth='lg'>
          <Box
            py={6}
            width='100%'
            display='flex'
            justifyContent='center'
            alignItems='center'
            flexDirection='column'
          >
            <Typography variant='h1' color='#303030'>
              Our Team
            </Typography>
            <Box pt={2} pb={5}>
              <Box
                bgcolor={palette.secondary.main}
                border={`4px solid ${palette.secondary.main}`}
                width='90px'
                borderRadius='4px'
              />
            </Box>
            <Box width='100%' pb={10}>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + WhitPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4'>Whit Symmes</Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Co-Founder & CEO
                      </Typography>
                    </Box>
                    {/* <Box pt="4px">
                      <Typography variant="body3" color="#808080" textAlign="center">
                        Co-Founder & CEO, Adelja Learning Inc.
                      </Typography>
                    </Box> */}
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Seattle, WA)
                      </Typography>
                    </Box>
                    <Box width='75%'>
                      <Typography
                        component='p'
                        variant='body4'
                        color='#808080'
                        textAlign='center'
                      >
                        <ReadMoreReact
                          text={
                            'Whit Symmes holds a BA in English Literature and a BFA in Music. He has taught in elementary and middle schools and worked as a private tutor for over 25 years. In 2010 he created Word Voyage to help students learn Latin roots, vocabulary, and grammar. At the company, Whit focuses on program design and teacher support.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + AbbyPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    />
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Abhishek Sharma
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Investor & CTO
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Bellevue, WA)
                      </Typography>
                    </Box>
                    <Box width='85%'>
                      <Typography
                        component='div'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            'Abhishek is a Computer Science Engineering (2010-14) graduate who started his career by joining Amazon in 2014 as a Software Engineer. During his tenure (2014-18) at Amazon he worked across 3 organizations (Amazon Transportation, Amazon Fresh and most recently Amazon Web Services) in designing & implementing high throughput and low latency software system architectures. In 2018, he founded his own technology startup - Yogaya and is actively working towards building data intensive and hybrid-cloud software products and solutions.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + JessicaPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4'>Jessica Ma</Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography variant='body3' color='#808080'>
                        Director of Tutoring
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography variant='body3' color='#808080'>
                        (New York, NY)
                      </Typography>
                    </Box>
                    <Box width='90%'>
                      <Typography
                        component='p'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            'Jessica is a seasoned professional with extensive experience in operational management, corporate finance and business strategic development. She worked in large international enterprises as well as start-ups and took on many roles including worldwide Controller, Chief Financial Officer, and Chief Operating Officer. Growing up with parents who were lifelong teachers, Jessica has always been passionate about education. As a mother of two, Jessica understands parents’ thirst for high quality education resources. After spending 20 years in the business world, Jessica co-founded LeapUp Learning. She now devotes herself to provide the best tutoring service.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box width='100%' pb={10}>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + YingPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    />
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Ying Song
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Director of Tutoring Curriculum
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (New York, NY)
                      </Typography>
                    </Box>
                    <Box width='85%'>
                      <Typography
                        component='div'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            'Ying studied Early Childhood Education and worked as a preschool teacher in China before coming to the United States in 1996. She then graduated from The Steinhardt School of Education in New York University in 2002 with a master’s degree in Bilingual Education. Ying has extensive teaching experience in both public and private schools. During her work at Shuangwen School, Yinghua language school and China Institute, Ying built up an expertise in bilingual curriculum design. She specializes in creating dynamic teaching and learning environments that align with her vision of a globalized community. Ying is a mother of three children and the co-founder of LearnUp Learning.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {/* <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + JonathanPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    />
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Jonathan Wu
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Engineering Lead
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Seattle, WA)
                      </Typography>
                    </Box>
                    <Box width='85%'>
                      <Typography
                        component='div'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            ' I’m an engineer based in Seattle. I’ve worked as a software engineer and tech lead on projects and teams building scalable systems for the travel industry. My past experience also includes building enterprise analytics and financial modeling systems at Intel. I love working across the stack and studying new technologies'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid> */}
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + WitahPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    />
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Witah Georjane
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Engineering Lead
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Douala, CMR)
                      </Typography>
                    </Box>
                    <Box pt={3} width='85%'>
                      <Typography
                        component='div'
                        textAlign='justify'
                        variant='body4'
                        color='#808080'
                      ></Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + AbdelPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    />
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Mounkambou Abdel Karim
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Software Engineer
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Douala, CMR)
                      </Typography>
                    </Box>
                    <Box pt={3} width='85%'>
                      <Typography
                        component='div'
                        textAlign='justify'
                        variant='body4'
                        color='#808080'
                      ></Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* <Box width='100%' display='flex' pb={10}>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + JavisPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Javis Nche
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        DevOps Engineer
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Canada)
                      </Typography>
                    </Box>
                    <Box pt={3} width='100%'>
                      <Typography
                        component='div'
                        textAlign='justify'
                        variant='body4'
                        color='#808080'
                      ></Typography>
                    </Box>
                  </Box>
                </Grid>
           

                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + YannickPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Yannick Mendzana
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        UI/UX & Graphics Designer
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Douala, CMR)
                      </Typography>
                    </Box>
                    <Box pt={3} width='85%'>
                      <Typography
                        component='div'
                        textAlign='justify'
                        variant='body4'
                        color='#808080'
                      ></Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}

            <Box width='100%' pb={10}>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + AashishPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Aashish Sharma
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Software Engineer Intern
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Bengaluru, IN)
                      </Typography>
                    </Box>
                    <Box pt={3} width='85%'>
                      <Typography
                        component='div'
                        textAlign='justify'
                        variant='body4'
                        color='#808080'
                      ></Typography>
                    </Box>
                  </Box>
                </Grid>
                {/* <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + EmmaPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Ngufor Emmanuella
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Software Engineer
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Douala, CMR)
                      </Typography>
                    </Box>
                    <Box pt={3} width='85%'>
                      <Typography
                        component='div'
                        textAlign='justify'
                        variant='body4'
                        color='#808080'
                      ></Typography>
                    </Box>
                  </Box>
                </Grid> */}
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + OliviaPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    />
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Olivia Cobian
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Tutor
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Deeth, NV)
                      </Typography>
                    </Box>
                    <Box width='85%'>
                      <Typography
                        component='div'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            'Olivia Cobian loves helping students engage in the English language through reading, writing, grammar study, and learning vocabulary through word analysis. She began teaching ELA and ESL at the high school level immediately after graduating with her BA from BYU. While teaching high school, she earned her Master’s of Education degree with an emphasis on teaching reading at SUU. Her Master’s project was developing a writing curriculum in her high school ESL classroom. When she became a mother, Olivia left full time teaching and devoted herself to raising and eventually homeschooling her five children until they reach high school age. She comes with the experience of a former high school teacher and a homeschooling mother. Olivia currently works part time as an online reading specialist and as a tutor for Word Voyage.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + AprilPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        April Halacy
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Tutor
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Provincetown, MA)
                      </Typography>
                    </Box>
                    <Box width='85%'>
                      <Typography
                        component='div'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            ' April Halacy is an education professional with 25 years experience teaching students individually, in small groups, and in a public school classroom, grades K-8. As a teacher, she excels at motivating students as individuals to help them achieve their academic and personal goals. She is passionate about helping students believe in themselves and their ability to succeed. For the past 5 years April has been working as an online teacher in English Language Arts, teaching students from the ages of 4 years old to 16 years old. Her work with online students has included all aspects of reading and writing instruction.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box width='100%' pb={10}>
              <Grid container>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + SarahPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Sarah Evans-Politte
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Tutor
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (Galveston, TX)
                      </Typography>
                    </Box>
                    <Box width='85%'>
                      <Typography
                        component='div'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            'Sarah Evans-Politte’ is a certified teacher and highly qualified in Early Childhood Education, Elementary 1-8, Reading Specialist EC-12, ESL EC-12, TESOL, and Special Education. Over the course of her 25+ years as an educator, she has 14 years of teaching PreK-5 and 15 years as the high school campus reading specialist/dyslexia designee for grades 9-12. As a campus designer for ESL, Dyslexia, and Reading Programs for 15 years, Sarah has taught small groups and has tutored students ages 4-18 to speak, listen, read, and write on grade level with intensive research-based instruction. Through her unique and personalized approach, her students are able to develop study habits and effective learning strategies that last. Her greatest joy is watching students succeed and unlock their own potential. Sarah retired in 2019 and found my niche as a virtual tutor for ESL, Dyslexia intervention, English Language Arts, and Reading instruction for students in grades EC-12. Sarah loves the freedom of working from home teaching students both in the US and around the world. Her hobbies include traveling, beachcombing, open water swimming, and making mosaics. She  also loves playing with her two dogs and cat. Sarah looks forward to being a part of your child’s Word Journey.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <Box
                      width='214px'
                      height='214px'
                      borderRadius='50%'
                      style={{
                        backgroundImage: 'url(' + JenniferPic + ')',
                        boxShadow:
                          'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
                        filter:
                          'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
                      }}
                    ></Box>
                    <Box pt={3}>
                      <Typography variant='h4' textAlign='center'>
                        Jennifer Connell-Wandstrat
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        Tutor
                      </Typography>
                    </Box>
                    <Box pt='4px'>
                      <Typography
                        variant='body3'
                        color='#808080'
                        textAlign='center'
                      >
                        (St. Petersburg, FL)
                      </Typography>
                    </Box>
                    <Box width='95%'>
                      <Typography
                        component='div'
                        textAlign='center'
                        variant='body4'
                        color='#808080'
                      >
                        <ReadMoreReact
                          text={
                            'Jennifer has 28 years of experience in a variety of educational roles. After earning a Bachelor of Science Degree in Elementary Education, she taught in a classroom for 9 years where she focused on adapting a rigid private school curriculum to an experiential learning process within her classroom. For the past 14 years, Jennifer has worked as a full-time tutor with in-person as well as online students from around the world. She has tutored students from Kindergarten through College in a range of subjects from phonics and reading to English as a Second Language and test preparation. Jennifer is the mom of 4 homeschooled children. Her two oldest have been dual-enrolled in college since age 16. She is an experienced homeschooling mom and the challenges parents and children face within this growing community. She is also a BSA Scoutmaster for an all-girls troop and Chair of the local Council of Catholic Women.'
                          }
                          min={0}
                          ideal={0}
                          max={0}
                          readMoreText='(read more)'
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box className={mobile}>
        <AboutTeamMobile />
      </Box>
    </>
  );
};
