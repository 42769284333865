import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import AbbyPic from '../../assets/abhishek_sharma.png';
import JavisPic from '../../assets/javis.jpg';
import WhitPic from '../../assets/whit_symmes.png';
import JessicaPic from '../../assets/jessica_ma.png';
import YingPic from '../../assets/ying_song.png';
import AprilPic from '../../assets/april_halacy.png';
import JenniferPic from '../../assets/jennifer_connell_wandstrat.png';
import OliviaPic from '../../assets/olivia_cobian.png';
import SarahPic from '../../assets/sarah_evans_politte.png';
import WitahPic from '../../assets/witah_georjane.png';
import JonathanPic from '../../assets/Jonathan.png';
import YannickPic from '../../assets/yannick.png';
import EmmaPic from '../../assets/Emma.png';
import AbdelPic from '../../assets/abdel_karim_mounkambou.png';
import AashishPic from '../../assets/aashish.png';
import ReadMoreReact from 'read-more-react';

const TEAM = [
  {
    name: 'Whit Symmes',
    subtitle: 'Co-Founder & CEO',
    place: '(Seattle, WA)',
    pic: WhitPic,
    bio: 'Whit Symmes holds a BA in English Literature and a BFA in Music. He has taught in elementary and middle schools and worked as a private tutor for over 25 years. In 2010 he created Word Voyage to help students learn Latin roots, vocabulary, and grammar. At the company, Whit focuses on program design and teacher support.',
  },
  {
    name: 'Abhishek Sharma',
    title: 'Investor & CTO',
    place: '(Bellevue, WA)',
    pic: AbbyPic,
    bio: 'Abhishek is a Computer Science Engineering (2010-14) graduate who started his career by joining Amazon in 2014 as a Software Engineer. During his tenure (2014-18) at Amazon he worked across 3 organizations (Amazon Transportation, Amazon Fresh and most recently Amazon Web Services) in designing & implementing high throughput and low latency software system architectures. In 2018, he founded his own technology startup - Yogaya and is actively working towards building data intensive and hybrid-cloud software products and solutions.',
  },
  {
    name: 'Jessica Ma',
    title: 'Director of Tutoring',
    place: '(New York, NY)',
    pic: JessicaPic,
    bio: 'Jessica is a seasoned professional with extensive experience in operational management, corporate finance and business strategic development. She worked in large international enterprises as well as start-ups and took on many roles including worldwide Controller, Chief Financial Officer, and Chief Operating Officer. Growing up with parents who were lifelong teachers, Jessica has always been passionate about education. As a mother of two, Jessica understands parents’ thirst for high quality education resources. After spending 20 years in the business world, Jessica co-founded LeapUp Learning. She now devotes herself to provide the best tutoring service.',
  },
  {
    name: 'Ying Song',
    title: 'Director of Tutoring Curriculum',
    place: '(New York, NY)',
    pic: YingPic,
    bio: 'Ying studied Early Childhood Education and worked as a preschool teacher in China before coming to the United States in 1996. She then graduated from The Steinhardt School of Education in New York University in 2002 with a master’s degree in Bilingual Education. Ying has extensive teaching experience in both public and private schools. During her work at Shuangwen School, Yinghua language school and China Institute, Ying built up an expertise in bilingual curriculum design. She specializes in creating dynamic teaching and learning environments that align with her vision of a globalized community. Ying is a mother of three children and the co-founder of LearnUp Learning.',
  },
  {
    name: 'Jonathan Wu',
    title: 'Engineering Lead',
    place: '(Seattle, WA)',
    pic: JonathanPic,
    bio: ' I’m an engineer based in Seattle. I’ve worked as a software engineer and tech lead on projects and teams building scalable systems for the travel industry. My past experience also includes building enterprise analytics and financial modeling systems at Intel. I love working across the stack and studying new technologies',
  },
  {
    name: 'Witah Georjane',
    title: 'Engineering Lead',
    place: '(Douala, CMR)',
    pic: WitahPic,
    bio: '',
  },
  {
    name: 'Javis Nche',
    title: 'DevOps Engineer',
    place: '(Canada)',
    pic: JavisPic,
    bio: '',
  },
  {
    name: 'Yannick Mendzana',
    title: 'UI/UX & Graphics Designer',
    place: '(Douala, CMR)',
    pic: YannickPic,
    bio: '',
  },
  {
    name: 'Mounkambou Abdel Karim',
    title: 'Software Engineer',
    place: '(Douala, CMR)',
    pic: AbdelPic,
    bio: '',
  },

  {
    name: 'Aashish Sharma',
    title: 'Software Engineer Intern',
    place: '(Bengaluru, IN)',
    pic: AashishPic,
    bio: '',
  },
  {
    name: 'Ngufor Emmanuella',
    title: 'Software Engineer',
    place: '(Douala, CMR)',
    pic: EmmaPic,
    bio: '',
  },
  {
    name: 'Olivia Cobian',
    title: 'Tutor',
    place: '(Deeth, NV)',
    pic: OliviaPic,
    bio: 'Olivia Cobian loves helping students engage in the English language through reading, writing, grammar study, and learning vocabulary through word analysis. She began teaching ELA and ESL at the high school level immediately after graduating with her BA from BYU. While teaching high school, she earned her Master’s of Education degree with an emphasis on teaching reading at SUU. Her Master’s project was developing a writing curriculum in her high school ESL classroom. When she became a mother, Olivia left full time teaching and devoted herself to raising and eventually homeschooling her five children until they reach high school age. She comes with the experience of a former high school teacher and a homeschooling mother. Olivia currently works part time as an online reading specialist and as a tutor for Word Voyage.',
  },
  {
    name: 'April Halacy',
    title: 'Tutor',
    place: '(Provincetown, MA)',
    pic: AprilPic,
    bio: 'April Halacy is an education professional with 25 years experience teaching students individually, in small groups, and in a public school classroom, grades K-8. As a teacher, she excels at motivating students as individuals to help them achieve their academic and personal goals. She is passionate about helping students believe in themselves and their ability to succeed. For the past 5 years April has been working as an online teacher in English Language Arts, teaching students from the ages of 4 years old to 16 years old. Her work with online students has included all aspects of reading and writing instruction.',
  },
  {
    name: 'Sarah Evans-Politte',
    title: 'Tutor',
    place: '(Galveston, TX)',
    pic: SarahPic,
    bio: 'Sarah Evans-Politte’ is a certified teacher and highly qualified in Early Childhood Education, Elementary 1-8, Reading Specialist EC-12, ESL EC-12, TESOL, and Special Education. Over the course of her 25+ years as an educator, she has 14 years of teaching PreK-5 and 15 years as the high school campus reading specialist/dyslexia designee for grades 9-12. As a campus designer for ESL, Dyslexia, and Reading Programs for 15 years, Sarah has taught small groups and has tutored students ages 4-18 to speak, listen, read, and write on grade level with intensive research-based instruction. Through her unique and personalized approach, her students are able to develop study habits and effective learning strategies that last. Her greatest joy is watching students succeed and unlock their own potential. Sarah retired in 2019 and found my niche as a virtual tutor for ESL, Dyslexia intervention, English Language Arts, and Reading instruction for students in grades EC-12. Sarah loves the freedom of working from home teaching students both in the US and around the world. Her hobbies include traveling, beachcombing, open water swimming, and making mosaics. She also loves playing with her two dogs and cat. Sarah looks forward to being a part of your child’s Word Journey.',
  },
  {
    name: 'Jennifer Connell-Wandstrat',
    title: 'Tutor',
    place: '(St. Petersburg, FL)',
    pic: JenniferPic,
    bio: 'Jennifer has 28 years of experience in a variety of educational roles. After earning a Bachelor of Science Degree in Elementary Education, she taught in a classroom for 9 years where she focused on adapting a rigid private school curriculum to an experiential learning process within her classroom. For the past 14 years, Jennifer has worked as a full-time tutor with in-person as well as online students from around the world. She has tutored students from Kindergarten through College in a range of subjects from phonics and reading to English as a Second Language and test preparation. Jennifer is the mom of 4 homeschooled children. Her two oldest have been dual-enrolled in college since age 16. She is an experienced homeschooling mom and the challenges parents and children face within this growing community. She is also a BSA Scoutmaster for an all-girls troop and Chair of the local Council of Catholic Women.',
  },
];

const PersonProfile = ({ pic, name, title, bio, place, subtitle }) => {
  return (
    <Box
      width='320px'
      display='flex'
      alignItems='center'
      flexDirection='column'
    >
      <Box
        width='214px'
        height='214px'
        borderRadius='50%'
        style={{
          backgroundImage: 'url(' + pic + ')',
          boxShadow: 'inset 0px 0px 0px 1px rgba(255, 255, 255, 0.2)',
          filter:
            'drop-shadow(-4.08333px -4.08333px 12.25px #FFFFFF) drop-shadow(6.125px 6.125px 12.25px rgba(174, 174, 192, 0.4))',
        }}
      ></Box>
      <Box pt={3}>
        <Typography variant='h4' color='#34566A' textAlign='center'>
          {name}
        </Typography>
      </Box>
      <Box pt='4px'>
        <Typography variant='body3' color='#808080' textAlign='center'>
          {title}
        </Typography>
      </Box>
      <Box pt='4px'>
        <Typography variant='body3' color='#808080' textAlign='center'>
          {subtitle}
        </Typography>
      </Box>
      <Box pt='4px'>
        <Typography variant='body3' color='#808080' textAlign='center'>
          {place}
        </Typography>
      </Box>
      <Box>
        <Typography
          component='div'
          textAlign='center'
          variant='body4'
          color='#808080'
        >
          <ReadMoreReact
            text={bio}
            min={0}
            ideal={0}
            max={0}
            readMoreText='(read more)'
          />
        </Typography>
      </Box>
    </Box>
  );
};

export const AboutTeamMobile = () => {
  return (
    <Box minHeight='100vh' position='relative'>
      <Container maxWidth='sm'>
        <Box
          pt={6}
          pb={6}
          display='flex'
          alignItems='center'
          width='100%'
          flexDirection='column'
        >
          <Typography variant='h1' color='#303030'>
            Our Team
          </Typography>
          <Box pt={2} mb={6}>
            <Box
              bgcolor='#FFCD00'
              height='4px'
              width='48px'
              borderRadius='4px'
            />
          </Box>
          <Box>
            <Grid
              container
              direction='column'
              spacing={8.5}
              alignItems='center'
            >
              {TEAM.map((person) => (
                <Grid item alignItems='center' key={person.name}>
                  <PersonProfile {...person} />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};
