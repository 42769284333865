import { Box, Container, Grid } from "@mui/material";
import React from "react";
// import { useScrollPercentage } from "react-scroll-percentage";
import { SchoolBodyGradeFourToFiveContent } from "./school-body-grade-four-to-five-content";
import { SchoolBodyGradeFourToFiveTabs } from "./school-body-grade-four-to-five-tabs";
import { SchoolBodyGradeNineToTwelveTabs } from "./school-body-grade-nine-to-twelve-tabs";
import { SchoolBodyGradeSixToEightContent } from "./school-body-grade-six-to-eight-content";
import { SchoolBodyGradeSixToEightTabs } from "./school-body-grade-six-to-eight-tabs";
import { SchoolBodyGradeNineToTwelveContent } from "./school-body-grade-nine-to-twelve-content";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
export const SchoolBody = ({ grade }) => {
  const { desktop, mobile } = useStyles();
  // const [ref, percentage] = useScrollPercentage();

  return (
    <Container maxWidth="lg">
      <Box  px={12} pt={10} pb={10} className={desktop}>
        {grade === "4-5" && (
          <Grid container>
            {/* <Grid item xs={3}>
              <SchoolBodyGradeFourToFiveTabs percentage={percentage} />
            </Grid> */}
             <Grid item xs={3}>
              <SchoolBodyGradeFourToFiveTabs />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <SchoolBodyGradeFourToFiveContent />
            </Grid>
          </Grid>
        )}
        {grade === "6-8" && (
          <Grid container>
            <Grid item xs={3}>
              <SchoolBodyGradeSixToEightTabs />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <SchoolBodyGradeSixToEightContent />
            </Grid>
          </Grid>
        )}
        {grade === "9-12" && (
          <Grid container>
            <Grid item xs={3}>
              <SchoolBodyGradeNineToTwelveTabs />
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={8}>
              <SchoolBodyGradeNineToTwelveContent />
            </Grid>
          </Grid>
        )}
      </Box>
      <Box className={mobile}>
        {grade === "4-5" && (
          <Box pt={8} pb={6}>
            <SchoolBodyGradeFourToFiveContent />
          </Box>
        )}
        {grade === "6-8" && (
          <Box pt={12.5} pb={15}>
            <SchoolBodyGradeSixToEightContent />
          </Box>
        )}
        {grade === "9-12" && (
          <Box pt={12.5} pb={15}>
            <SchoolBodyGradeNineToTwelveContent />
          </Box>
        )}
      </Box>
    </Container>
  );
};
