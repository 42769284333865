import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import App from "./App";
import "./index.css";

import { ThemeProvider } from "./theme/theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider>
      <Router>
        <App />
      </Router>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
