import { useTheme } from "@emotion/react";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/wv.svg";
import logoMobile from "../../assets/wv_logo.svg";

const useStyles = makeStyles((theme) => ({
  desktop: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  mobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const openMenu = () => setMenu(true);
  const closeMenu = () => setMenu(false);
  const { palette } = useTheme();
  const styles = useStyles();
  return (
    <>
      <Box bgcolor="transparent" className={styles.desktop} zIndex={1}>
        <Box
          px={12}
          width="100%"
          height="80px"
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid container rowSpacing={6}>
            <Grid item xs={3}>
              <Box pt="" display="flex" alignItems="center">
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Typography
                    fontSize="25px"
                    fontWeight="bold"
                    color={palette.common.white}
                  >
                    <img style={{width: "230px" }} src={logo} />
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={5}>
              <Box
                display="flex"
                justifyContent="space-evenly"
                width="100"
                alignItems="center"
                height="100%"
                pt="15px"
              >
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Typography fontSize="18px" variant="button1" color={palette.common.white}>
                    Home
                  </Typography>
                </Link>
                <Link to="/school" style={{ textDecoration: "none" }}>
                  <Typography fontSize="18px" variant="button1" color={palette.common.white}>
                    Schools
                  </Typography>
                </Link>
                <Link to="/tutoring" style={{ textDecoration: "none" }}>
                  <Typography fontSize="18px" variant="button1" color={palette.common.white}>
                    Tutoring
                  </Typography>
                </Link>
                {/* <Link to="/" style={{ textDecoration: "none" }}>
                  <Typography variant="button1" color={palette.common.white}>
                    Resources
                  </Typography>
                </Link> */}
                <Link to="/about" style={{ textDecoration: "none" }}>
                  <Typography fontSize="18px" variant="button1" color={palette.common.white}>
                    About
                  </Typography>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={4}>
                
              <Box pt="15px" display="flex" justifyContent="flex-end" alignItems="center">
                <a
                  href="https://wordvoyage.com/wv-vocabulary/register-studentorteacher.php"
                  style={{ textDecoration: "none" }}
                  target="_blank"
                >
                  <Typography
                    variant="button"
                    style={{
                      paddingRight: "36px",
                      textTransform: "none",
                      color: palette.common.white,
                    }}
                  >
                    Register
                  </Typography>
                </a>
                
                <a
                    href="https://wordvoyage.com/wv-vocabulary/wv-login.php"
                    style={{ textDecoration: "none" }}
                    target="_blank"
                >
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "32px",
                      backgroundColor: "#FFCD00",
                      color: "#34566A",
                      paddingRight: "40px",
                      paddingLeft: "40px",
                      padding: "10px 40px 10px 40px",
                    }}
                  >
                    
                      <Typography
                        variant="button"
                        color="#34566A"
                        style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                      >
                        Log In
                      </Typography>
                  </Button>
                </a>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className={styles.mobile} bgcolor="transparent">
        <Box
          pt={4}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Link to="/" onClick={closeMenu} style={{ textDecoration: "none" }}>
            <Typography variant="body2" color={palette.common.white}>
              <img src={logoMobile} />
            </Typography>
          </Link>
          <IconButton onClick={openMenu}>
            <Box color={palette.common.white}>
              <MenuIcon />
            </Box>
          </IconButton>
        </Box>
      </Box>
      <Box
        height="100vh"
        width="100vw"
        zIndex={menu ? 2 : -1}
        display={menu ? "block" : "none"}
        top={0}
        right={0}
        left={0}
        bottom={0}
        bgcolor="#34566A"
        position="fixed"
        overflow="hidden"
      >
        <Box pt={4} width="100%" display="flex" flexDirection="column">
          <Container maxWidth="lg">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="body2" color={palette.common.white}>
                <img src={logoMobile} />
              </Typography>
              <IconButton onClick={closeMenu}>
                <Box color={palette.common.white}>
                  <CloseIcon />
                </Box>
              </IconButton>
            </Box>
          </Container>
          <Box pt={10} />
          <Grid container direction="column" spacing={5} align="center">
            <Grid item>
              <Link
                to="/"
                onClick={closeMenu}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="body3" color={palette.common.white}>
                  Home
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/school"
                onClick={closeMenu}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="body3" color={palette.common.white}>
                  Schools
                </Typography>
              </Link>
            </Grid>
            <Grid item>
              <Link
                to="/tutoring"
                onClick={closeMenu}
                style={{ textDecoration: "none" }}
              >
                <Typography variant="body3" color={palette.common.white}>
                  Tutoring
                </Typography>
              </Link>
            </Grid>
            {/* <Grid item>
              <Box>
                <Typography variant="body3" color={palette.common.white}>
                  Resources
                </Typography>
              </Box>
            </Grid> */}
            <Grid item>
              <Box>
              <Link to="/about" style={{ textDecoration: "none" }}>
                <Typography variant="body3" color={palette.common.white}>
                  About
                </Typography>
              </Link>
              </Box>
            </Grid>
          </Grid>
            <Grid item xs={4}>
          <Box
            pt={15}
            display="flex"
            justifyContent="center"
            width="100vw"
            px={2}
            // pb={15}
          >

                
          <a
                href="https://wordvoyage.com/wv-vocabulary/register-studentorteacher.php"
                style={{ textDecoration: "none" }}
                target="_blank"
          >  
                <Typography
                  component="p"
                  variant="button"
                  color="white"
                  style={{ textTransform: "none", textDecoration: "none", color: "white" }}
                >
                  Register
                </Typography>  
            </a>
          </Box>
          </Grid>
          <Grid item xs={4}>
          <Box
            pt={5}
            display="flex"
            justifyContent="center"
            width="100vw"
            px={2}
            // pb={15}
          >

                
          <a
                href="https://wordvoyage.com/wv-vocabulary/wv-login.php"
                style={{ textDecoration: "none" }}
                target="_blank"
          >            
              <Button
                variant="contained"
                fullWidth
                style={{
                  borderRadius: "32px",
                  backgroundColor: "#FFCD00",
                  color: "#34566A",
                  paddingRight: "42px",
                  paddingLeft: "42px",
                  padding: "14px 42px 14px 42px",
                }}
              >
                <Typography
                  component="p"
                  variant="button"
                  color="#34566A"
                  style={{ textTransform: "none", textDecoration: "none", color: "#303030" }}
                >
                  Log In
                </Typography>  
              </Button>
            </a>
          </Box>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
